'use client'

import Alert, { DismissAlertButton } from '@/components/Alert'
import { type ReactNode, useEffect, useRef } from 'react'
import useAppErrorStore from './useAppErrorStore'

export default function AppWarning({ extra }: { extra?: ReactNode }) {
  const { warning, resetWarning } = useAppErrorStore()

  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [alertRef.current])

  if (!warning) {
    return
  }

  if (warning.extraComponent) {
    return (
      <Alert
        title={warning.friendlyWarning}
        variant="warning"
        ref={alertRef}
        buttons={<DismissAlertButton key={'dismiss'} onDismiss={resetWarning} />}
        description={
          <>
            {warning.extraComponent}
            {extra}
          </>
        }
      />
    )
  }

  return (
    <Alert
      title="Warning"
      variant="warning"
      ref={alertRef}
      buttons={<DismissAlertButton key={'dismiss'} onDismiss={resetWarning} />}
      description={
        <>
          <p>{warning.friendlyWarning}</p>

          {extra}
        </>
      }
    />
  )
}
