import { Button } from '@/components/ui/Button'
import { ArrowsCounterClockwise } from '@phosphor-icons/react'
import { twMerge } from 'tailwind-merge'
import UserChatSuggestion from './UserChatSuggestion'
export interface ChatSuggestionsProps {
  isNewChat: boolean
  suggestedQuestions: string[]
  setSuggestedQuestion: (question: string) => void
  getSuggestionQuestions: () => void
  className?: string
  replica: string
}

export function ChatSuggestions({
  isNewChat,
  suggestedQuestions = [],
  setSuggestedQuestion,
  getSuggestionQuestions,
  className,
  replica,
}: ChatSuggestionsProps) {
  if (!isNewChat) return null
  if (!suggestedQuestions.length) return null

  return (
    <div className={twMerge('flex flex-col items-end gap-3', className)}>
      <div className="flex items-center">
        <h6 className="mr-[10px] select-none">Suggested questions</h6>
        <ArrowsCounterClockwise
          color="#a3426c"
          className="cursor-pointer"
          onClick={getSuggestionQuestions}
          weight="bold"
          height={18}
          width={18}
        />
      </div>

      {suggestedQuestions.map((question: string, index: number) => (
        <Button
          className="text-wrap h-fit py-2 select-none rounded-br-lg text-right transition-transform duration-300 last:mb-2 hover:-translate-x-2"
          onClick={(e) => {
            e.preventDefault()
            setSuggestedQuestion(question)
          }}
          type="submit"
          size="small"
          variant="secondary"
          key={index}
        >
          {question}
        </Button>
      ))}
      <UserChatSuggestion replica={replica} />
    </div>
  )
}
