'use client'
import useAppErrorStore from '@/app/studio/useAppErrorStore'
import { Button } from '@/components/ui/Button'
import Spinner from '@/components/ui/Spinner'
import { PaperPlaneRight, Play, X } from '@phosphor-icons/react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Dictation from './dictation'
import useHeygenAvatar from './use-heygen-avatar'

const VideoStreaming = forwardRef(function VideoStreaming(
  {
    className,
    avatarId,
    replicaSlug,
    autoplay = false,
    silenceDuration = 1500,
  }: {
    className?: string
    autoplay?: boolean
    silenceDuration?: number
    avatarId: string
    replicaSlug: string
  },
  ref,
) {
  const mediaStream = useRef<HTMLVideoElement>(null)

  const { error, resetError, setError, setWarning } = useAppErrorStore()

  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [muted, setMuted] = useState<boolean>(true)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isResponding, setIsResponding] = useState<boolean>(false)

  const {
    stream,
    startSession,
    endSession,
    append,
    sessionRunning,
    isLoadingSession,
    input,
    handleSubmit,
    handleInputChange,
  } = useHeygenAvatar({
    avatarId,
    replicaSlug,
    onError: (message, error) => setError({ error, friendlyError: message }),
    onWarning: (message, extraComponent) => setWarning({ friendlyWarning: message, extraComponent }),
    setIsResponding,
  })

  const hidePlayer = !autoplay && !sessionRunning && !isLoadingSession

  useImperativeHandle(ref, () => ({
    streamHandler,
  }))

  function streamHandler() {
    if (stream) endSession()
    else startSession()
  }

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream

      const handlePlay = () => setIsPlaying(true)
      const handlePause = () => setIsPlaying(false)

      mediaStream.current.addEventListener('play', handlePlay)
      mediaStream.current.addEventListener('pause', handlePause)

      // Cleanup function to remove event listeners
      return () => {
        if (mediaStream.current) {
          mediaStream.current.removeEventListener('play', handlePlay)
          mediaStream.current.removeEventListener('pause', handlePause)
          mediaStream.current.onloadedmetadata = null
        }

        // endSession()
      }
    }
  }, [stream])

  useEffect(() => {
    if (!autoplay) return

    startSession()
  }, [autoplay])

  if (hidePlayer) return <></>

  const isLoading = isLoadingSession || !sessionRunning

  return (
    <div
      className={twMerge(
        'relative group h-[420px] w-[98%] mx-auto overflow-hidden rounded md:h-[500px] md:w-full md:bottom-10 z-10',
        !isPlaying && 'bg-burgundy',
        className,
      )}
    >
      {!isPlaying && stream && (
        <div
          onClick={() => {
            mediaStream.current?.play()
            inputRef.current?.focus()
            setMuted(false)
          }}
          className="group cursor-pointer absolute inset-0 bg-black/50 z-20 text-white grid place-content-center"
        >
          <span>
            <Play className="group-hover:scale-110 transition-all" size={30} />
          </span>
        </div>
      )}

      {stream && !autoplay && (
        <button
          className="absolute right-2 top-2 z-20 rounded-full bg-white/30 p-1 text-sm text-black opacity-30 transition-all duration-300 group-hover:opacity-100"
          onClick={endSession}
        >
          <X size={20} />
        </button>
      )}

      <form
        onSubmit={(e) => {
          handleSubmit(e)
          setIsResponding(true)
          if (error) resetError()
        }}
        className={twMerge(
          'absolute inset-x-0 bottom-0 pb-4 pt-2 px-4 mx-auto z-10',
          // , !stream && 'opacity-50'
        )}
      >
        <fieldset
          className="videoStreamingInputBg flex items-center border border-burgundy py-2 px-2.5 rounded-md gap-2"
          disabled={!stream}
        >
          <input
            ref={inputRef}
            autoFocus
            className="px-0 h-auto w-full resize-none overflow-y-auto border-0 bg-transparent text-off-white placeholder:text-white/50 focus-within:outline-none focus:ring-0"
            value={input}
            onChange={handleInputChange}
            placeholder="Tap to type or press mic to start speaking..."
            disabled={!stream}
          />

          <Dictation
            append={append}
            listeningPaused={isResponding}
            silenceDuration={silenceDuration}
            disabled={!stream}
            onSubmitted={() => setIsResponding(true)}
          />

          <Button className="shrink-0 disabled:bg-transparent disabled:border-transparent" size="icon" type="submit">
            <PaperPlaneRight weight="fill" />
          </Button>
        </fieldset>
      </form>

      {stream && (
        <video
          ref={mediaStream}
          autoPlay={false}
          muted={muted}
          playsInline
          width={240}
          height={320}
          style={{
            objectFit: 'cover',
          }}
          className="h-full w-full object-cover"
        />
      )}

      {isLoading && (
        <div className="flex h-full w-full items-center justify-center text-white bg-black">
          <Spinner size={40} />
        </div>
      )}
    </div>
  )
})

export default VideoStreaming
