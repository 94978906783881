import type { SensayPlan } from '@/app/pricing/[[...slugs]]/stripe-plans'
import { Button } from '@/components/ui/Button'
import Link from 'next/link'

type StudioUpgradePlanAlertProps = {
  requiredPlan: SensayPlan
}

function UpgradePlanAlert({ requiredPlan }: StudioUpgradePlanAlertProps) {
  return (
    <div className="flex flex-col gap-6 p-6 bg-off-white rounded-md">
      <div className="flex flex-col gap-2">
        <p className="font-medium">You've reached your chat limit</p>
        <div>
          You've hit the chat limit for the Free plan, but don't worry—you can still send more messages for now! Upgrade
          to the Starter or Professional plan to unlock more chats, premium features, and get the most out of your
          replicas.
        </div>
      </div>

      <Button className="ml-auto" asChild>
        <Link href={`/pricing/year/${requiredPlan}`}>Upgrade plan</Link>
      </Button>
    </div>
  )
}

export default UpgradePlanAlert
