import type { FeatureCategoryKey, FeatureKey } from '@/lib/plan'
import z from 'zod'

export const STARTER_SENSAY_PLAN = 'starter'
export const PROFESSIONAL_SENSAY_PLAN = 'professional'
export const ENTERPRISE_SENSAY_PLAN = 'enterprise'
export const FREE_SENSAY_PLAN = 'free'

export const frequencies = ['month', 'year'] as const

export const SALES_EMAIL = 'mailto:sales@sensay.io'

export type Frequency = (typeof frequencies)[number]

export const FREE_NOT_SIGNED_IN_MAX_CHAT_MESSAGES = 6
const FREE_SIGNED_IN_MAX_CHAT_MESSAGES = 50
const STARTER_MAX_CHAT_MESSAGES = 1000
const PRO_MAX_CHAT_MESSAGES = 5000

export function isCurrentPlan({
  currentPlan,
  plan,
  planFrequency,
  userPlanFrequency,
}: {
  currentPlan: SensayPlan | ''
  plan: SensayPlan
  planFrequency: Frequency
  userPlanFrequency: Frequency | undefined | ''
}) {
  if (currentPlan === FREE_SENSAY_PLAN && plan === currentPlan) return true

  return currentPlan === plan && planFrequency === userPlanFrequency
}

export function getFeatureDetails<
  T extends FeatureCategoryKey,
  F extends FeatureKey<T>,
  P extends keyof (typeof featureCategories)[T][F],
>(category: T, feature: F, plan: P, withFormatter = false) {
  const featureObject = featureCategories[category][feature] as any
  if (withFormatter) {
    return featureObject.formatter ? featureObject.formatter(featureObject[plan]) : featureObject[plan]
  }
  return featureObject[plan]
}

export const FrequencyStrictSchema = z.enum(['month', 'year'])
export const FrequencySchema = z.enum(['month', 'year', ''])

export const stripeProductsToPlans: Record<string, SensayPlan> = {
  // production
  prod_QlPZ4ohqwMW9rn: STARTER_SENSAY_PLAN,
  prod_QlPZUFSLJvrxxt: PROFESSIONAL_SENSAY_PLAN,

  // legacy
  prod_QBRSGY2JeSilpf: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSQTPtA3lGAB: PROFESSIONAL_SENSAY_PLAN, // ok with flowtrade customer was Pro Voice
  prod_QBRS8cQyQzabH2: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSCbJOGwaL4w: PROFESSIONAL_SENSAY_PLAN, // ok with pictographs.io and arqexchange.io customer was Pro
  prod_Oty5PkV1VwZWVQ: PROFESSIONAL_SENSAY_PLAN, // ok with customers was Your Replica
  prod_OjBjFU7AgDsTo6: STARTER_SENSAY_PLAN, // ok with customers was premium

  // test env
  prod_QlLS8bxspfGx5o: STARTER_SENSAY_PLAN,
  prod_QlLUup7wieRVwm: PROFESSIONAL_SENSAY_PLAN,
}

export type SensayPlan = z.infer<typeof SensayPlanSchema>

const sensayPlans = [FREE_SENSAY_PLAN, STARTER_SENSAY_PLAN, PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN] as const
export const SensayPlanSchema = z.enum(sensayPlans)

export const validProPlans: SensayPlan[] = [PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN]

export function getHigherPlan(plan: SensayPlan): SensayPlan | undefined {
  switch (plan) {
    case FREE_SENSAY_PLAN:
      return STARTER_SENSAY_PLAN
    case STARTER_SENSAY_PLAN:
      return PROFESSIONAL_SENSAY_PLAN
    case PROFESSIONAL_SENSAY_PLAN:
      return ENTERPRISE_SENSAY_PLAN
    case ENTERPRISE_SENSAY_PLAN:
      return undefined
  }
}

// const COMMUNITY_TELEGRAM_LINK = 'https://t.me/asksensay'

export type StripePlan = {
  plan: SensayPlan
  title: string
  iconPath: string
  detail: string
  textColor: string
  colorClassName: string
  buttonClassName: string
  discountTextColor?: string
  features: string[]
  featuresTitle: string
  keyFeatures: string[]
  month: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
  year: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
  gtmId: string
}

export const FEATURE_LANGAUGES = '100+ Languages' as const
export const FEATURE_TRAINING_ASSISTANT = 'Training Assistant' as const
export const FEATURE_YOUTUBE_TRAINING = 'YouTube Trainings' as const
export const FEATURE_FILE_TRAINING = 'File Trainings' as const
export const FEATURE_WEB_LINK_TRAINING = 'Web Trainings' as const
export const FEATURE_DATA_ENCRYPTION = 'Data Encryption' as const
export const FEATURE_COMMUNITY_ACCESS = 'Community Access' as const
export const FEATURE_PRIVATE_REPLICAS = 'Private Replicas' as const
export const FEATURE_QUESTS_TO_EARN_TOKENS = 'Quests to Earn Tokens' as const
export const FEATURE_TRAINING_COURSES = 'Training Courses' as const
export const FEATURE_AUTOMATED_WORKFLOWS = 'Automated Workflows' as const
export const FEATURE_CUSTOM_PERSONALITY = 'Custom Personality' as const
export const FEATURE_ACCURACY_TOOLS = 'Accuracy Tools' as const
export const FEATURE_CUSTOM_STORAGE = 'Custom Storage' as const
export const FEATURE_IMPORT_ELEVENLABS_API_KEY = 'Import ElevenLabs API key' as const
export const FEATURE_DISCORD_INTEGRATION = 'Discord Copilot' as const
export const FEATURE_TELEGRAM_INTEGRATION = 'Telegram Copilot' as const
export const FEATURE_VOICE_INTERACTIONS = 'Voice Interactions' as const
export const FEATURE_VIDEO_INTERACTIONS = 'Video Interactions' as const
export const FEATURE_WEBSITE_WIDGET = 'Website Widget' as const
export const FEATURE_FULL_AUTOMATION = 'Full Automation' as const
export const FEATURE_DEDICATED_SUPPORT = 'Dedicated Support' as const
export const FEATURE_API_ACCESS = 'API Access' as const
export const FEATURE_CUSTOM_INTEGRATIONS = 'Custom Integrations' as const

export const featureCategories = {
  Replicas: {
    Replicas: {
      [FREE_SENSAY_PLAN]: 1,
      [STARTER_SENSAY_PLAN]: 3,
      [PROFESSIONAL_SENSAY_PLAN]: 10,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Digital assistants that can interact with anyone',
      link: 'https://feedback.sensay.io/features/p/public-replicas',
      formatter: (value: string | number) =>
        Number.isFinite(value) ? `${value} ${value === 1 ? 'Replica' : 'Replicas'}` : 'Unlimited Replicas',
    },
    [FEATURE_PRIVATE_REPLICAS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Digital assistants accessible only to you or selected users',
      link: 'https://feedback.sensay.io/features/p/private-replicas',
    },
  },
  'Messaging & Storage': {
    'Messages per Day': {
      [FREE_SENSAY_PLAN]: FREE_SIGNED_IN_MAX_CHAT_MESSAGES,
      [STARTER_SENSAY_PLAN]: STARTER_MAX_CHAT_MESSAGES,
      [PROFESSIONAL_SENSAY_PLAN]: PRO_MAX_CHAT_MESSAGES,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Number of interactions your replicas can have daily',
      formatter: (value: string | number) => (Number.isFinite(value) ? `${value} Messages` : 'Unlimited Messaging'),
    },
    [FEATURE_LANGAUGES]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Your replicas can communicate in over 100 languages',
    },
  },
  'Training & Interaction': {
    [FEATURE_TRAINING_ASSISTANT]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Guided tool to help you teach your replica',
      link: 'https://feedback.sensay.io/replica-training/p/train-your-replica-with-guided-qa-custom-questions',
    },
    [FEATURE_YOUTUBE_TRAINING]: {
      [FREE_SENSAY_PLAN]: 3,
      [STARTER_SENSAY_PLAN]: 50,
      [PROFESSIONAL_SENSAY_PLAN]: 500,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Learn from YouTube videos',
      link: 'https://feedback.sensay.io/replica-training/p/train-your-replica-with-files-links',
      formatter: (value: string | number) =>
        Number.isFinite(value) ? `${value} Youtube Trainings` : 'Unlimited Youtube Links',
    },
    [FEATURE_FILE_TRAINING]: {
      [FREE_SENSAY_PLAN]: 3,
      [STARTER_SENSAY_PLAN]: 50,
      [PROFESSIONAL_SENSAY_PLAN]: 500,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Learn from YouTube videos',
      link: 'https://feedback.sensay.io/replica-training/p/train-your-replica-with-files-links',
      formatter: (value: string | number) => (Number.isFinite(value) ? `${value} File Trainings` : 'Unlimited Files'),
    },
    [FEATURE_WEB_LINK_TRAINING]: {
      [FREE_SENSAY_PLAN]: 3,
      [STARTER_SENSAY_PLAN]: 50,
      [PROFESSIONAL_SENSAY_PLAN]: 500,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Learn from YouTube videos',
      link: 'https://feedback.sensay.io/replica-training/p/train-your-replica-with-files-links',
      formatter: (value: string | number) =>
        Number.isFinite(value) ? `${value} Web Trainings` : 'Unlimited Web Links',
    },
    [FEATURE_DISCORD_INTEGRATION]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your replica on Discord for community management',
      link: 'https://feedback.sensay.io/integrations/p/discord-integration',
    },
    [FEATURE_TELEGRAM_INTEGRATION]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Deploy your replica on Telegram for messaging',
      link: 'https://feedback.sensay.io/integrations/p/telegram-integration',
    },
    [FEATURE_VOICE_INTERACTIONS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Speak with your replica using voice commands',
      link: 'https://feedback.sensay.io/features/p/voice-interactions',
    },
    [FEATURE_VIDEO_INTERACTIONS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Speak with your replica using voice commands',
      link: 'https://feedback.sensay.io/features/p/face-to-face-video-interactions',
    },
    [FEATURE_WEBSITE_WIDGET]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Speak with your replica using voice commands',
      link: 'https://feedback.sensay.io/features/p/custom-voice-integration-with-elevenlabs',
    },
    [FEATURE_IMPORT_ELEVENLABS_API_KEY]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your own ElevenLabs API key to add a custom voice to your replica',
      hidden: true,
    },
    [FEATURE_CUSTOM_PERSONALITY]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Tailor your replica's behavior and traits",
      link: 'https://feedback.sensay.io/features/p/custom-personality',
    },
  },
  // "Monetize Your Replica": {
  //   [FEATURE_EARN_WITH_REFERRALS]: {
  //     [FREE_SENSAY_PLAN]: true,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Get rewards when others sign up using your link",
  //     link: 'https://feedback.sensay.io/use-cases/p/quests-to-earn-tokens',
  //   },
  //   "Monetize Your Replica": {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Earn money from your replica's interactions",
  //   },
  //   [FEATURE_QUESTS_TO_EARN_TOKENS]: {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Complete tasks to earn $SNSY tokens",
  //   },
  // },
  'Automation & Customization': {
    [FEATURE_AUTOMATED_WORKFLOWS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Set up your replica to perform tasks automatically',
      link: 'https://feedback.sensay.io/integrations/p/automated-content-syncing-with-social-media',
    },
    [FEATURE_FULL_AUTOMATION]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Create complex, fully automated processes with your replicas',
    },
  },
  'Support & Security': {
    [FEATURE_COMMUNITY_ACCESS]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Join our user community for support and collaboration',
      link: 'https://feedback.sensay.io/features/p/community-access',
    },
    [FEATURE_DEDICATED_SUPPORT]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Get personalized help from our support team',
    },
    [FEATURE_API_ACCESS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Integrate Sensay features into your own applications',
      link: 'https://feedback.sensay.io/features/p/api-access',
    },
    [FEATURE_DATA_ENCRYPTION]: {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Keep your replica's data secure and private",
      link: 'https://feedback.sensay.io/features/p/data-encryption',
    },
    [FEATURE_ACCURACY_TOOLS]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Advanced features to improve your replica's responses",
      link: 'https://feedback.sensay.io/features/p/hallucination-checker-ensure-trusted-fact-checked-answers',
    },
    [FEATURE_CUSTOM_STORAGE]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Tailored data storage solutions for your needs',
      link: 'https://feedback.sensay.io/features/p/custom-storage',
    },
    [FEATURE_TRAINING_COURSES]: {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Access educational resources to maximize your use of replicas',
    },
  },
} as const

export type FeatureCategory = keyof typeof featureCategories

export const stripePlans: Record<SensayPlan, StripePlan> = {
  [FREE_SENSAY_PLAN]: {
    plan: FREE_SENSAY_PLAN,
    title: 'Free',
    iconPath: '/assets/pricing/free-icon.svg',
    detail: 'Start exploring Sensay for free',
    featuresTitle: 'What You Get:',
    features: [
      getFeatureDetails('Replicas', 'Replicas', FREE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_YOUTUBE_TRAINING, FREE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_FILE_TRAINING, FREE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_WEB_LINK_TRAINING, FREE_SENSAY_PLAN, true),
      FEATURE_TRAINING_ASSISTANT,
      FEATURE_DATA_ENCRYPTION,
      FEATURE_COMMUNITY_ACCESS,
    ],
    keyFeatures: [],
    textColor: 'text-burgundy',
    colorClassName: 'bg-bright-green border border-bright-green',
    buttonClassName: 'hover:bg-bright-green-light active:bg-bright-green-dark',
    month: {
      price: '0',
      liveId: '',
      testId: '',
    },
    year: {
      price: '0',
      liveId: '',
      testId: '',
    },
    gtmId: '',
  },
  [STARTER_SENSAY_PLAN]: {
    plan: STARTER_SENSAY_PLAN,
    title: 'Starter',
    iconPath: '/assets/pricing/start-icon.svg',
    detail: 'Create more replicas with enhanced training',
    textColor: 'text-burgundy',
    colorClassName: 'bg-pastel-blue border border-pastel-blue',
    buttonClassName: 'hover:bg-pastel-blue-light active:bg-pastel-blue-dark',
    discountTextColor: 'text-pastel-blue-dark',
    featuresTitle: 'All Free Features, plus:',
    features: [
      getFeatureDetails('Replicas', 'Replicas', STARTER_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_YOUTUBE_TRAINING, STARTER_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_FILE_TRAINING, STARTER_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_WEB_LINK_TRAINING, STARTER_SENSAY_PLAN, true),
      FEATURE_PRIVATE_REPLICAS,
      FEATURE_QUESTS_TO_EARN_TOKENS,
      FEATURE_TRAINING_COURSES,
      FEATURE_LANGAUGES,
    ],
    keyFeatures: ['Interact with Replicas', 'Create Replicas', 'Social Media Inputs', 'Earn from Your Replica*'],
    month: {
      yearlyDiscount: '$150',
      price: '45',
      liveId: 'price_1Q3bMXKuls4YqbSx4pg04do9',
      testId: 'price_1Q3dbMKuls4YqbSxLGlvEY7x',
    },
    year: {
      yearlyDiscount: '$150',
      price: '30',
      liveId: 'price_1Q3c5sKuls4YqbSxrDEgN9pQ',
      testId: 'price_1Q3db2Kuls4YqbSxedEnaPla',
    },
    gtmId: 'starter',
  },
  [PROFESSIONAL_SENSAY_PLAN]: {
    plan: PROFESSIONAL_SENSAY_PLAN,
    title: 'Professional',
    textColor: 'text-burgundy',
    colorClassName: 'bg-pastel-orange border border-pastel-orange',
    buttonClassName: 'hover:bg-pastel-orange-light active:bg-pastel-orange-dark',
    discountTextColor: 'text-pastel-orange-dark',
    iconPath: '/assets/pricing/professional-icon.svg',
    detail: 'Automate and scale your AI workforce',
    featuresTitle: 'All Starter Features, plus:',
    features: [
      getFeatureDetails('Replicas', 'Replicas', PROFESSIONAL_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_YOUTUBE_TRAINING, PROFESSIONAL_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_FILE_TRAINING, PROFESSIONAL_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_WEB_LINK_TRAINING, PROFESSIONAL_SENSAY_PLAN, true),
      FEATURE_DISCORD_INTEGRATION,
      FEATURE_TELEGRAM_INTEGRATION,
      FEATURE_WEBSITE_WIDGET,
      FEATURE_VOICE_INTERACTIONS,
      FEATURE_VIDEO_INTERACTIONS,
      FEATURE_CUSTOM_PERSONALITY,
    ],
    keyFeatures: [
      'Engage with Your Replicas',
      'Create Custom Replicas',
      'Integrate with Social Media',
      'Streamline Outputs to External Channels',
      'Automate Tasks with Autopilot',
      'Monetize Your Replica',
    ],
    month: {
      price: '225',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx8P0hp3gP',
      testId: 'price_1PtonOKuls4YqbSx9NIvXbVp',
    },
    year: {
      price: '150',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx5zUX9BnD',
      testId: 'price_1PtonOKuls4YqbSx399PRwE8',
    },
    gtmId: 'professional',
  },
  [ENTERPRISE_SENSAY_PLAN]: {
    plan: ENTERPRISE_SENSAY_PLAN,
    title: 'Enterprise',
    iconPath: '/assets/pricing/enterprise-icon.svg',
    detail: 'Custom AI solutions for enterprise scalability',
    featuresTitle: 'All Pro Features, plus:',
    textColor: 'text-burgundy',
    colorClassName: 'bg-pastel-purple border border-pastel-purple',
    buttonClassName: 'hover:bg-pastel-purple-light active:bg-pastel-purple-dark',
    features: [
      getFeatureDetails('Replicas', 'Replicas', ENTERPRISE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_YOUTUBE_TRAINING, ENTERPRISE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_FILE_TRAINING, ENTERPRISE_SENSAY_PLAN, true),
      getFeatureDetails('Training & Interaction', FEATURE_WEB_LINK_TRAINING, ENTERPRISE_SENSAY_PLAN, true),
      FEATURE_CUSTOM_INTEGRATIONS,
      FEATURE_API_ACCESS,
      FEATURE_FULL_AUTOMATION,
      FEATURE_DEDICATED_SUPPORT,
    ],
    keyFeatures: [],
    month: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
    year: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
    gtmId: 'talk-to-sales',
  },
}

export type Features = {
  [feature: string]: PlanDetails
}

// biome-ignore lint/suspicious/noExplicitAny:
export type FormatterType = (value: any) => string | number

export type PlanDetails = {
  tooltip: string
  link?: string
  hidden?: boolean
  formatter?: FormatterType
} & Record<SensayPlan, boolean | string | number>
