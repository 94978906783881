export const deploymentEnvironment = (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) as
  | 'production'
  | 'development'
  | 'preview'

export function getDeploymentUrl() {
  switch (deploymentEnvironment) {
    case 'production': {
      const url = process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL || process.env.VERCEL_PROJECT_PRODUCTION_URL
      return `https://${url}`
    }
    case 'preview':
    case 'development': {
      const url = process.env.NEXT_PUBLIC_VERCEL_URL || process.env.VERCEL_URL
      return `https://${url}`
    }
  }
}

export const deploymentUrl = getDeploymentUrl()
