import { XCircle } from '@phosphor-icons/react'
import * as Popover from '@radix-ui/react-popover'
import { useState } from 'react'

const CHUNK_REF_CLASSNAME =
  'font-bold text-primary underline underline-offset-2 transition-opacity duration-300 hover:opacity-70'

export function ChunkReferenceLink({
  source: { score, source_name, name, source_content, content },
  index,
}: {
  source: {
    score?: number
    // TODO: Remove 'source_name' in future versions. We're keeping both 'name' and 'source_name'
    // temporarily for backwards compatibility during migration. 'name' is the new standard.
    source_name?: string | null
    name?: string | null
    // TODO: Remove 'source_content' in future versions. We're keeping both 'content' and 'source_content'
    // temporarily for backwards compatibility during migration. 'content' is the new standard.
    source_content?: string
    content?: string
  }
  index: number
}) {
  const [enabled, setEnabled] = useState(false)

  return (
    <Popover.Root open={enabled} onOpenChange={setEnabled}>
      <Popover.Trigger asChild>
        <button
          className={CHUNK_REF_CLASSNAME}
          onClick={() => {
            setEnabled((oldEnabled) => !oldEnabled)
          }}
        >
          [{`${index}`}]
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="max-w-[--radix-popper-available-width] px-5 z-25">
          <div className="bg-white p-4 rounded-md shadow-md whitespace-pre-wrap max-h-60 overflow-y-auto min-w-80">
            <Popover.Close className="absolute top-2 right-10">
              <XCircle size={24} />
            </Popover.Close>
            <div>
              <div className="-mt-4 flex flex-col justify-start">
                {score && <span className="mt-3 text-gray-500 text-sm w-full">{score * 100}% confidence</span>}
                {(source_name || name) && (
                  <span className="mt-3 text-gray-500 text-sm w-full">{source_name ?? name}</span>
                )}
              </div>
              <div>{content ?? source_content}</div>
            </div>
          </div>
          <Popover.Arrow className="fill-white w-5 h-3" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
