'use client'
import useAppErrorStore from '@/app/studio/useAppErrorStore'
import { Button } from '@/components/ui/Button'
import useWhisperTranscription from '@/hooks/useWhisperTranscription'
import { CircleNotch, Microphone, Stop } from '@phosphor-icons/react'
import type { useChat } from 'ai/react'
import { memo, useEffect } from 'react'
import ShortUniqueId from 'short-unique-id'
import { twMerge } from 'tailwind-merge'

const Dictation = memo(
  ({
    append,
    silenceDuration,
    disabled,
    listeningPaused,
    onSubmitted,
  }: {
    append: ReturnType<typeof useChat>['append']
    silenceDuration: number
    disabled: boolean
    listeningPaused: boolean
    onSubmitted?: () => void
  }) => {
    const { setError } = useAppErrorStore()

    const { isListening, formatDuration, duration, isProcessing, toggleRecording } = useWhisperTranscription({
      setInput: (input: string) => {
        const { randomUUID } = new ShortUniqueId({ length: 10 })
        const uuid = randomUUID()
        append({
          id: uuid,
          content: input,
          role: 'user',
          createdAt: new Date(),
        })
      },
      isReplicaTyping: listeningPaused,
      silenceDuration,
      onError: (message: string, error: Error) => {
        setError({
          friendlyError: message,
          error,
        })
      },
    })

    useEffect(() => {
      if (isProcessing) {
        onSubmitted?.()
      }
    }, [isProcessing])

    return (
      <Button
        className={twMerge(
          'flex items-center justify-center shrink-0 disabled:bg-transparent disabled:border-transparent',
          isListening && 'w-28',
        )}
        onClick={toggleRecording}
        type="button"
        variant="secondary"
        size={isProcessing || isListening ? 'small' : 'icon'}
        disabled={isProcessing || disabled}
      >
        {isListening ? (
          <>
            <Stop className="flex-shrink-0 text-red-500" />
            <span className="ml-2 text-sm text-red-500"> {formatDuration(duration)}</span>
          </>
        ) : listeningPaused || isProcessing ? (
          <CircleNotch className="animate-spin" />
        ) : (
          <Microphone className="flex-shrink-0" />
        )}
      </Button>
    )
  },
)

export default Dictation
