'use client'

import Alert, { DismissAlertButton, SendFeedbackAlertButton } from '@/components/Alert'
import { Bug } from '@phosphor-icons/react/dist/ssr'
import { type ReactNode, useEffect, useRef } from 'react'
import useAppErrorStore, { type AppErrorState } from './useAppErrorStore'

export default function AppError({ extra }: { extra?: ReactNode }) {
  const { error, resetError } = useAppErrorStore()
  if (!error) {
    return
  }

  const { sentryId } = error

  if (!sentryId) {
    return <ExpectedError error={error} extra={extra} resetError={resetError} />
  }

  return <UnexpectedError error={error} extra={extra} resetError={resetError} />
}

function ExpectedError({
  error,
  extra,
  resetError,
}: { error: NonNullable<AppErrorState['error']>; extra?: ReactNode; resetError: () => void }) {
  const { friendlyError, friendlyErrorDetail } = error

  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [alertRef.current])

  return (
    <Alert
      title={friendlyError}
      variant="destructive"
      ref={alertRef}
      buttons={<DismissAlertButton key="dismiss" onDismiss={resetError} />}
      description={
        friendlyErrorDetail || extra ? (
          <>
            {friendlyErrorDetail}

            {extra}
          </>
        ) : undefined
      }
    />
  )
}

function UnexpectedError({
  error,
  extra,
  resetError,
}: { error: NonNullable<AppErrorState['error']>; extra?: ReactNode; resetError: () => void }) {
  const { friendlyError, displayError, sentryId, stack } = error
  const isDev = process.env.NODE_ENV === 'development'
  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [alertRef.current])
  return (
    <Alert
      variant="destructive"
      title={friendlyError}
      ref={alertRef}
      buttons={[
        <DismissAlertButton key="dismiss" onDismiss={resetError} />,
        <SendFeedbackAlertButton key="feedback" />,
      ]}
      description={
        <>
          <p>An error occurred, please try again later.</p>
          {extra}
          <pre>
            <Bug className="inline" /> {sentryId}
          </pre>
          <p>Send us your feedback if this problem continues to occur.</p>
          {isDev && (
            <div className="bg-neutral-200 border-l-3 border-neutral-600 p-4 mt-4 overflow-x-auto rounded-md">
              <p className="font-bold">Development ONLY debug information</p>
              <p>{displayError}</p>
              <pre className="overflow-x-auto">Stack: {stack}</pre>
            </div>
          )}
        </>
      }
    />
  )
}
