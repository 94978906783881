import { captureException } from '@sentry/nextjs'
import { ZodError } from 'zod'

export class ServerActionError extends Error {
  sentryId: string | null
  friendlyMessage: string | null

  constructor(friendlyMessage: string | null, error: unknown, sentryId?: string | null) {
    let message = friendlyMessage ?? 'An error occurred'
    let errorObject = null
    let stack: string | undefined = undefined

    try {
      errorObject = JSON.parse(error as string)
      stack = errorObject.stack
    } catch {}
    if (
      typeof errorObject === 'object' &&
      errorObject !== null &&
      'message' in errorObject &&
      typeof errorObject.message === 'string'
    ) {
      message = errorObject.message
    }
    super(message)
    this.name = this.constructor.name
    this.stack = stack
    this.sentryId = sentryId || null
    this.friendlyMessage = friendlyMessage
  }
}

export class ValidationError extends Error {
  constructor(friendlyMessage: string) {
    super(friendlyMessage)
    this.name = this.constructor.name
  }
}

export type ServerActionResponse<T = unknown> =
  | ['error', { friendlyError: string | null; error: unknown; sentryId?: string | null }]
  | ['success', T]

export type ServerActionSuccessPayload<T> = T extends ['success', infer U] ? U : never

/**
 * Because Next.js doesn't let through error messages from server actions to the client,
 * we are serializing potential errors and deserializing them on the client via wrapServerActionCall.
 */
export async function wrapServerAction<R>(callback: () => Promise<R>): Promise<ServerActionResponse<R>> {
  try {
    return ['success', await callback()]
  } catch (error) {
    let friendlyError: string | null = null
    let sentryId = null

    const isDevelopment = process.env.NODE_ENV === 'development'

    if (error instanceof ZodError) {
      friendlyError = error.errors.map((zodError) => `${zodError.path.join('.')}: ${zodError.message}`).join('\n')
    } else if (error instanceof ValidationError) {
      friendlyError = error.message
    } else if (error instanceof Error) {
      friendlyError = null
      sentryId = captureException(error)
    } else if (typeof error === 'string') {
      friendlyError = error
    }

    return [
      'error',
      {
        friendlyError,
        error: isDevelopment ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : {},
        sentryId,
      },
    ]
  }
}

export type ServerAction<T extends unknown[], R = unknown> = (...args: T) => Promise<ServerActionResponse<R>>

/**
 * This function wraps a server action and throws an error if the server action returns an error.
 */
export function wrapServerActionCall<T extends unknown[], R>(
  serverAction: ServerAction<T, R>,
): (...args: T) => Promise<R> {
  return async (...args: T): Promise<R> => {
    const response = await serverAction(...args)

    if (!response) {
      throw new Error('Server action returned an empty response')
    }

    if (response[0] === 'error') {
      throw new ServerActionError(response[1].friendlyError, response[1].error, response[1].sentryId)
    }

    return response[1]
  }
}
