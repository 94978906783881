import useAppErrorStore from '@/app/studio/useAppErrorStore'
import useMessageStreamingCallback from '@/hooks/useMessageStreamingCallback'
import { useChat } from 'ai/react'

export default function useReplicaChat({
  onNewSentence,
  replicaSlug,
  onResponseFinished,
}: {
  onNewSentence: (sentence: string, isLast: boolean) => Promise<void>
  replicaSlug: string
  onResponseFinished?: () => void
}) {
  const { setError } = useAppErrorStore()

  const now = new Date()
  const localTimeString = new Date(now.getTime() - now.getTimezoneOffset() * 60_000).toISOString()

  const {
    input,
    handleSubmit,
    handleInputChange,
    append,
    isLoading: isReplicaReplying,
    messages,
  } = useChat({
    body: {
      date: localTimeString,
    },
    onFinish: () => {
      onResponseFinished?.()
    },
    onError(error) {
      setError({
        friendlyError: 'Uh oh, something went wrong!',
        error,
      })
    },
    api: `/api/chat/${replicaSlug}/web`,
  })

  const lastMessage = messages[messages.length - 1]
  const replicaText = lastMessage?.role === 'assistant' ? lastMessage.content || '' : ''

  useMessageStreamingCallback(replicaText, isReplicaReplying, onNewSentence)

  return {
    input,
    handleSubmit,
    handleInputChange,
    append,
    isReplicaReplying,
    replicaText,
  }
}
