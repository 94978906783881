'use client'

import type { Message } from 'ai'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { memo } from 'react'
import type { HistoryMessage } from './Chat'
import ChatMessage from './ChatMessage'

export type MessageWithContext = Message & { context?: string | null }

export interface ChatHistoryProps {
  favourited: boolean
  messages: HistoryMessage[]
  isReplicaTyping: boolean
  replicaSlug: string
  model: string
  replicaUuid: string
}

function addItemToFilteredMessagesObject(acc: Record<string, HistoryMessage[]>, message: HistoryMessage) {
  const date = moment(message.createdAt).format('YYYY-MM-DD')
  if (!acc[date]) {
    acc[date] = []
  }
  acc[date] = [...acc[date], message]
  return acc
}

export default memo(function ChatHistory({
  favourited,
  messages: _messages,
  isReplicaTyping,
  replicaSlug,
  model,
  replicaUuid,
}: ChatHistoryProps) {
  const [isFavourite, setIsFavourite] = useState<boolean | undefined>(favourited)

  const messages = [..._messages]
  const lastMessage = messages.at(-1)
  let typedMessage: HistoryMessage | null = null
  if (lastMessage && lastMessage.role === 'assistant' && isReplicaTyping) {
    typedMessage = messages.pop() || null
  }

  const _filteredMessages = useMemo(() => {
    return messages
      .filter((message) => message.id !== 'update') // hack to get a refresh
      .filter((message) => message.content)
      .reduce<Record<string, HistoryMessage[]>>(addItemToFilteredMessagesObject, {})
  }, [messages.length])

  const filteredMessages = { ..._filteredMessages }
  if (typedMessage) {
    // filteredMessages["test"] = "kokot"
    addItemToFilteredMessagesObject(filteredMessages, typedMessage)
  }

  return Object.keys(filteredMessages)?.map((date) => (
    <div className="flex flex-col relative" key={date}>
      <div className="self-center">
        <p className="text-xs text-inherit opacity-50">{date}</p>
      </div>

      <div className="flex flex-col gap-5 gap-y-8">
        {filteredMessages[date].map((message, index) => (
          <ChatMessage
            key={message.id}
            {...message}
            isReplicaTyping={isReplicaTyping && index === messages.length - 1}
            replicaSlug={replicaSlug}
            isFavourite={isFavourite}
            setIsFavourite={setIsFavourite}
            model={model}
            createdAt={String(message.createdAt)}
            replicaUuid={replicaUuid}
          />
        ))}
      </div>
    </div>
  ))
})
